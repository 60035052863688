
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import { mapGetters } from 'vuex'

    const logo = require('./../../../assets/imgs/logo.png')
    @Component({
        name: 'DashCampusintro',
        computed: mapGetters('common', ['branchSchoolId'])
    })
    export default class DashCampusintro extends Vue {
        branchSchoolId!: any

        private center: any = {}
        private zoom = 12
        private showZoomInfo = false
        private curMarkerItem: any = {}

        private map: any = {}
        private mapTab: Array<any> = ['全部校区']
        private mapList: Array<any> = []
        private pagingDataList: Array<any> = []
        private total: Array<number> = []
        private mapTabActiveIndex = 0

        private mapListActiveIndex = 0
        private totalActiveIndex = 0
        private pageSize = 6

        private markerList: Array<any> = [
            {}, {},
            {
                title: '好问-合肥分校',
                lng: 117.2666037109,
                lat: 31.7999466940,
                list: [
                    {
                        area: '亳州路校区',
                        address: '庐阳区亳州路301号金地大厦',
                        tel: '69106630',
                        city: '庐阳区',
                        markerPoint: {
                            lng: 117.2646182614,
                            lat: 31.8880301682
                        },
                        show: false
                    },
                    {
                        area: '五里墩校区',
                        address: '蜀山区长江西路与合作化南路交口金域国际',
                        tel: '69106630',
                        city: '蜀山区',
                        markerPoint: {
                            lng: 117.2563281597,
                            lat: 31.8579923217
                        },
                        show: false
                    },
                    {
                        area: '千城大厦校区',
                        address: '滨湖新区徽州大道与朱雀街交口千城大厦',
                        tel: '69106630',
                        city: '滨湖新区',
                        markerPoint: {
                            lng: 117.2852400000,
                            lat: 31.7449200000
                        },
                        show: false
                    },
                    {
                        area: '市府广场校区',
                        address: '庐阳区安庆路79号天徽大厦B座',
                        tel: '69106630',
                        city: '庐阳区',
                        markerPoint: {
                            lng: 117.2924650938,
                            lat: 31.8692637419
                        },
                        show: false
                    },
                    {
                        area: '天鹅湖校区',
                        address: '政务区潜山路与高河东路交口绿地蓝海C座',
                        tel: '69106630',
                        city: '政务区',
                        markerPoint: {
                            lng: 117.239128,
                            lat: 31.828009
                        },
                        show: false
                    },
                    {
                        area: '大溪地校区',
                        address: '蜀山区黄山路与岳西路交口向北50米十里庙社区综合楼北楼',
                        tel: '69106630',
                        city: '蜀山区',
                        markerPoint: {
                            lng: 117.232094,
                            lat: 31.849359
                        },
                        show: false
                    },
                    {
                        area: '巴黎都市校区',
                        address: '湖北路佳源巴黎都市2期商业4栋201',
                        tel: '69106630',
                        city: '包河区',
                        markerPoint: {
                            lng: 117.313728,
                            lat: 31.74214
                        },
                        show: false
                    },
                    {
                        area: '天王巷校区',
                        address: '庐阳区天王巷4号',
                        tel: '69106630',
                        city: '庐阳区',
                        markerPoint: {
                            lng: 117.27632,
                            lat: 31.866521
                        },
                        show: false
                    },
                    {
                        area: '南薰门桥校区',
                        address: '徽州大道376号',
                        tel: '69106630',
                        city: '包河区',
                        markerPoint: {
                            lng: 117.292764,
                            lat: 31.855526
                        },
                        show: false
                    },
                    {
                        area: '义井路校区',
                        address: '义井路与合瓦路交口向西50米谊品生鲜旁',
                        tel: '69106630',
                        city: '庐阳区',
                        markerPoint: {
                            lng: 117.29074,
                            lat: 31.88944
                        },
                        show: false
                    },
                    {
                        area: '杏花公园校区',
                        address: '蒙城路79号中兴大厦三楼',
                        tel: '69106630',
                        city: '庐阳区',
                        markerPoint: {
                            lng: 117.284268,
                            lat: 31.873541
                        },
                        show: false
                    },
                    {
                        area: '玫瑰园校区',
                        address: '合肥市经开区繁华大道与芙蓉路交叉口决策大厦2号楼',
                        tel: '69106630',
                        city: '经开区',
                        markerPoint: {
                            lng: 117.197499,
                            lat: 31.788618
                        },
                        show: false
                    },

                    {
                        area: '栢景湾校区',
                        address: '亳州路与北一环交口天庆大厦3层',
                        tel: '69106630',
                        city: '经开区',
                        markerPoint: {
                            lng: 117.270145,
                            lat: 31.884381
                        },
                        show: false
                    },
                    {
                        area: '城隍庙校区',
                        address: '庐阳区霍邱路87号（城隍庙大世界喜品城5楼）',
                        tel: '69106630',
                        city: '庐阳区',
                        markerPoint: {
                            lng: 117.286039,
                            lat: 31.870201
                        },
                        show: false
                    },
                    {
                        area: '稻香楼校区',
                        address: '稻香楼',
                        tel: '69106630',
                        city: '蜀山区',
                        markerPoint: {
                            lng: 117.277473,
                            lat: 31.858985
                        },
                        show: false
                    },
                    {
                        area: '千城大厦校区',
                        address: '滨湖新区徽州大道与朱雀街交口千城大厦',
                        tel: '69106630',
                        city: '滨湖区',
                        markerPoint: {
                            lng: 117.291277,
                            lat: 31.751115
                        },
                        show: false
                    }
                ],
            },
            {}, {},
            {
                title: '好问-芜湖分校',
                lng: 118.408371,
                lat: 31.259294,
                list: [
                    {
                        area: '星隆国际校区',
                        address: '芜湖市镜湖区星隆国际城9号楼',
                        tel: '0553-5657882',
                        city: '芜湖市',
                        markerPoint: {
                            lng: 118.408371,
                            lat: 31.259294
                        },
                        show: false
                    },
                    {
                        area: '城南润地校区',
                        address: '九华南路润地商业广场1号楼302',
                        tel: '0553-5657882',
                        city: '芜湖市',
                        markerPoint: {
                            lng: 118.388102,
                            lat: 31.307012
                        },
                        show: false
                    },
                ]
            },
            {
                title: '好问-马鞍山分校',
                lng: 118.519275,
                lat: 31.702766,
                list: [
                    {
                        area: '马鞍山分校',
                        address: '马鞍山市湖北东路8号创客园25栋1~3层',
                        tel: '0555-3885985',
                        city: '马鞍山',
                        markerPoint: {},
                        show: false
                    },
                ]
            },
            {
                title: '好问-安庆分校',
                lng: 117.045892,
                lat: 30.521599,
                list: [
                    {
                        area: '安庆分校',
                        address: '安庆市大观区德宽路161-2号怡庭公寓一楼（徽商银行东侧）',
                        tel: '0556-5690112',
                        city: '安庆市',
                        markerPoint: {},
                        show: false
                    },
                ]
            },
            {
                title: '好问-南京分校',
                lng: 118.745738,
                lat: 32.064546,
                list: [
                    {
                        area: '南京分校',
                        address: '南京市鼓楼区江东北路305号',
                        tel: '025-58821490',
                        city: '南京',
                        markerPoint: {},
                        show: false
                    },
                ]
            },
            {
                title: '好问-苏州分校',
                lng: 120.631944,
                lat: 31.322137,
                list: [
                    {
                        area: '苏州分校',
                        address: '苏州市姑苏区干将东路666号和基广场',
                        tel: '0512-68438771',
                        city: '苏州',
                        markerPoint: {},
                        show: false
                    },
                ]
            },
        ]

        @Watch('branchSchoolId', { immediate: true, deep: true })
        branchSchoolIdWatch(newVal: any): void {// 切换城市时候修改地图数据
            if(newVal) {
                this.setCenter(newVal)
                this.curMarkerItem = this.markerList[newVal]
                this.initMap()
                this.initMapTabList(-1)
            }
        }

        private initMapTabList (index: number): void {// 初始化地图列表（右侧列表）
            this.mapList = []
            if(index == -1) {// 初始化mapTab
                this.mapTab = ['全部校区']
                this.curMarkerItem.list.forEach((item: any, idx: number): void => {
                    this.mapTab.push(item.city)
                    this.mapList.push(item)
                })
                this.mapTab = Array.from(new Set(this.mapTab))
            }
            this.curMarkerItem.list.forEach((item: any, idx: number): void => {
                if(item.city == this.mapTab[index]) {
                    this.mapList.push(item)
                }
            })
            if(this.mapList.length > this.pageSize) {
                this.setPagingDataList(this.pageSize, 0)
            } else {
                this.pagingDataList = this.mapList
            }
            this.total = []
            for(let i = 0, len = Math.ceil(this.mapList.length / this.pageSize); i < len; i++) {
                this.total.push(i + 1)
            }
        }

        private setPagingDataList (pageSize: number, pageNum: number): void {// 设置地图列表分页信息
            this.pagingDataList = []
            this.mapList.forEach((item: any, index: number): void => {// 下标从0开始
                if(index < pageSize * (pageNum + 1) && index >= pageSize * pageNum) {
                    this.pagingDataList.push(item)
                }
            })
        }
        private setCenter (index: number): void {// 设置地图中心点位置
            this.center.lng = this.markerList[index].lng
            this.center.lat = this.markerList[index].lat
        }
        private mapTabClick (item: any, index: number): void {// 地图列表切换顶部导航
            if(this.mapTabActiveIndex != index) {
                this.mapTabActiveIndex = index
                this.totalActiveIndex = index
                this.initMapTabList(index == 0 ? -1 : index)
            }
        }
        private mapListEvent (item: any): void {// 地图列表元素点击事件
            this.mapListActiveIndex = item.area
            // 手动触发标记点
            const aDoms: any = document.getElementsByClassName('BMap_noprint')
            aDoms.forEach((itm: any, idx: number): void => {
                if(itm.title && itm.title == item.area) {
                    itm.click()
                }
            })
        }

        private totalEvent (index: number): void {
            this.totalActiveIndex = index
            this.setPagingDataList(this.pageSize, index)
        }

        private initMap (): void {// 初始化地图信息，添加标记点
            const _this: any = this,
                _win: any = window,
                _BMap: any = _win.BMap;

            setTimeout(() => {
                _this.map = new _BMap.Map('allmap')
                const navigationControl: any = new _BMap.NavigationControl({
                        anchor: _win.BMAP_ANCHOR_TOP_LEFT,
                        type: _win.BMAP_NAVIGATION_CONTROL_LARGE,// LARGE类型
                        enableGeolocation: false// 启用显示定位
                    });
                _this.map.addControl(navigationControl)
                _this.map.setMapStyle({
					style: 'normal'
                })
                const geolocationControl = new _BMap.GeolocationControl()
                _this.map.centerAndZoom(new _BMap.Point(_this.center.lng, _this.center.lat), 13)

                _this.curMarkerItem.list.forEach((item: any, index: number): void => {
                    const content = `<ul class="flex ai-c jc-s mine-map-cont">
                                                <li class="text">${item.area}：${item.address}</li>
                                                <li class="logo-wrapper"><img src="${logo}" /></li>
                                            </ul>`,
                        title: string = _this.curMarkerItem.title,
                        { lng, lat } = item.markerPoint,
                        poii: any = new _BMap.Point(lng, lat),
                        marker: any = new _BMap.Marker(poii);
                    // marker.setWidth = 30
                    // marker.setHeight = 30
                    marker.setTitle(item.area)
                    _this.map.addOverlay(marker)
                    _this.addClickHandler(content, marker, title)
                })
            }, 1000)
        }

        private addClickHandler(content: any, marker: any, title: string): void {// 地图标记点弹窗+点击事件
            const _this: any = this,
                _win: any = window,
                _BMapLib: any = _win.BMapLib,
                _SearchInfoWindow: any = new _BMapLib.SearchInfoWindow(_this.map, content, {
                    title: title, //标题
                    width: 404, //宽度
                    height: 'auto', //高度
                    enableSendToPhone: false, //隐藏发送短信按钮
                    panel: "gjlx", //检索结果面板
                    enableAutoPan: true, //自动平移
                    searchTypes: [
                        _win.BMAPLIB_TAB_SEARCH, //周边检索
                        _win.BMAPLIB_TAB_TO_HERE, //到这里去
                        _win.BMAPLIB_TAB_FROM_HERE //从这里出发
                    ]
                });
            marker.addEventListener("click", (e: any): void => {
                _SearchInfoWindow.open(marker)
            })
        }
    }


    import { Component, Vue, Watch } from 'vue-property-decorator'
    let dr_1 = require('./../../../assets/imgs/dr_1.png'),
        dr_2 = require('./../../../assets/imgs/dr_2.png'),
        dr_3 = require('./../../../assets/imgs/dr_3.png');
    @Component({
        name: 'DashResearch'
    })
    export default class DashResearch extends Vue {
        private drContentList: Array<any> = [
            { icon: dr_1, title: 'Accurate Prediction', text: 'Many times of accurate prediction', info1: 'Fang Tian’s handouts have repeatedly predicted<br> questions of high school entrance examination, the<br/> mid-term and final examination of famous school,<br />and No. 45 Middle Schools,No. 42 Middle School, <br/>ShouChun Middle School, Fang Tian’s handouts have<br/> repeatedly predicted ' +
                  'questions of high school entrance<br/> examination, the ' +
                  'mid-term and final examination of <br/> famous school, and \n' +
                  'No. 45 Middle Schools, No. 50<br/> Middle Schools, No. 42 \n' +
                  'Middle School, ShouChun<br/> Middle School, No. 1 Middle \n' +
                  'School, No. 6 Middle<br/> School, No. 8 Middle School, 168 \n' +
                  'Middle School and<br/> other middle and high schools\n' +
                  'Questions', info2: 'Every time students perfectly miss the questions<br/> they had done. The type of questions in the<br/> exam was very rare' },
            { icon: dr_2, title: 'Nice Question Picking', text: 'Independent Books Research', info1: 'The teaching materials used by Fangtian are <br/>' +
                  'self-developed handouts, and the teaching and <br/>' +
                  'research system formulated by the teacher after <br/>' +
                  'layers of review conforms to the students’ <br/>' +
                  'cognitive laws and the rules of examination <br/>' +
                  'propositions. And at the same time implements <br/>' +
                  'hierarchical teaching, and uses different levels of <br/>' +
                  'handouts according to the class type. ', info2: 'Students have done many questions, but their<br/> effect ' +
                  'of raising points is poor, and the effect<br/> of the sea of ' +
                  'questions tactics is getting worse<br/> and worse' },
            { icon: dr_3, title: 'Modularization', text: 'Solution of A Type of Questions', info1: 'The example questions selected by FangTian in each<br/> lesson are all centered on the' +
                  'knowledge points, and<br/> the difficulty is progressive. Not only allowing<br/> students to understand the knowledge points but<br/> also ensuring that students will solve each '+
                  'problem<br/> and each type of problem. Then through the pre-class<br/> test and post-class test, the knowledge points are<br/> continuously consolidated.', info2: 'After being changed into another form, the same <br/>' +
                  'question cannot be done, and repetition of the <br/> same ' +
                  'question will always lose points' }
        ]
    }


    import { Component, Vue, Watch } from 'vue-property-decorator'
    let dg_icon_1 = require('./../../../assets/imgs/dg_icon_1.png'),
        dg_icon_2 = require('./../../../assets/imgs/dg_icon_2.png'),
        dg_icon_3 = require('./../../../assets/imgs/dg_icon_3.png');
    @Component({
        name: 'DashGoodteaching'
    })
    export default class DashGoodteaching extends Vue {
        private dgContentLists: Array<any> = [
            { title: 'Teaching', subTitle1: 'Interactive Courseware', text1: 'The lower grades of primary school use interactive courseware with colorful styles that make children fall in love with learning', subTitle2: 'Small-class Teaching', text2: 'The class size is around 20 people,ensuring that each child has a good learning environment', icon: dg_icon_1 },
            { title: 'Learning', subTitle1: 'Parents Accompany', text1: 'Parents are allowed to enter the classroom to obse- rve and grow up with their children if they meet the requirements', subTitle2: 'Face-to-face Teaching', text2: 'Offline tutoring,face-to-face communication with teachers,can close the distance with teachers', icon: dg_icon_2 },
            { title: 'Practice', subTitle1: 'Mistake Book', text1: 'Record the child’s wrong questions,' +
                  'check them at any time,and consolidate the knowledge points by recording their own problems', subTitle2: 'Day-day Practice', text2: 'One question per day to consolidate the knowledge learned in class and help students review their own class content', icon: dg_icon_3 }
        ]
    }

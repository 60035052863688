
    import { Component, Vue, Watch } from 'vue-property-decorator'

    import { mapGetters, mapActions } from 'vuex'
    @Component({
        name: 'DashTeacher',
        computed: {
            ...mapGetters('teacher', ['swiperTeachers'])
        },
        methods: {
            ...mapActions('teacher', ['setTeacherList'])
        }
    })
    export default class DashTeacher extends Vue {

        setTeacherList!: any
        swiperTeachers!: Array<any>
        
        private teachersList: Array<any> = []
        private teachersListWidth: number = 0

        private leftPos: number = 0
        private timer: any = null
        private animated: boolean = false
        private index: number = 0
        private len: number = 0
        private interval: number = 3000
        private times: number = 0


        @Watch('swiperTeachers', { immediate: true, deep: true })
        swiperTeachersWatch(newVal: Array<any>): void {
            if(newVal && newVal.length > 0) {
                // this.leftPos = newVal.length * (-280) - 24 * (newVal.length - 1)
                this.leftPos = 0
                this.teachersList = [ ...newVal ]
                this.len = this.teachersList.length
                this.teachersListWidth = this.teachersList.length * 280 + (this.teachersList.length - 1) * 24
            }
        }

        private animate (offset: number): void {
            if(offset == 0) {
                return
            }
            this.animated = true
            let _this: any = this,
                _time: number = 152,
                _interval: number = 2,
                speed: number = offset / (_time / _interval),
                _left: number = parseInt(_this.leftPos) + offset,
                go = function () {
                    if((speed > 0 && parseInt(_this.leftPos) < _left) || (speed < 0 && parseInt(_this.leftPos) > _left)) {
                      console.log('1......')
                        _this.leftPos = parseInt(_this.leftPos) + speed
                        setTimeout(go, _interval)
                    } else {
                        _this.leftPos = _left
                        const width = _this.$refs.swipperbox.clientWidth
                        // if(Math.abs(_this.index) == _this.len / 3) {
                        //     _this.index = 0
                        //     _this.leftPos = _this.teachersList.length / 3 * (-280) - 24 * (_this.teachersList.length / 3 - 1)
                        // } else if (Math.abs(_this.index) > _this.len / 3) {
                        //   console.log('3.........')
                        //     _this.index = 0
                        //     _this.leftPos = 0
                        // }
                      console.log(width, '外部盒子狂赌')
                      if (_this.teachersListWidth - Math.abs(_this.leftPos) < width) { // 当页面滑动超出当前盒子之后让元素滑动会原本位置
                        _this.index = 0
                        _this.leftPos = 0
                      }

                        _this.animated = false
                    }
                };
            go()
        }
        private play () {
          const width: any = document.getElementById('swipper')?.clientWidth
          if (this.teachersListWidth - width >= 304) {
            this.timer = setTimeout(() => {
              this.swClickEvent(1)
              this.play()
            }, this.interval)
          }
        }
        private stop (): void {
            clearTimeout(this.timer);
        }
        private swClickEvent (index: number): void {
            this.stop()
            if(this.animated) {
              return
            }
            if(index == -1) {
                this.index -= 1
                this.animate(304)
            } else {
                this.index += 1
                this.animate(-304)
            }
        }

        private teacherItemEvent (item: any): void {
            // console.log(item)
            let _this: any = this
            _this.$router.push({ path: '/aboutUs/index', query: { id: item.id } })
        }
        beforeMount() {
            this.setTeacherList({ subject: '', type: 0 })
        }
        mounted () {
            this.play()
        }
    }

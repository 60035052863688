
    import { Component, Vue, Watch } from 'vue-property-decorator'
    let de_circle_1 = require('./../../../assets/imgs/de_circle_1.png'),
        de_circle_2 = require('./../../../assets/imgs/de_circle_2.png'),
        de_circle_3 = require('./../../../assets/imgs/de_circle_3.png'),
        de_circle_4 = require('./../../../assets/imgs/de_circle_4.png'),
        de_icon_1 = require('./../../../assets/imgs/de_icon_1.png'),
        de_icon_2 = require('./../../../assets/imgs/de_icon_2.png'),
        de_icon_3 = require('./../../../assets/imgs/de_icon_3.png'),
        de_icon_4 = require('./../../../assets/imgs/de_icon_4.png');
    @Component({
        name: 'DashEvaluation'
    })
    export default class DashEvaluation extends Vue {
        private decContWidth: number = 0
        private deContentList: Array<any> = [
            {
                circle: de_circle_1, icon: de_icon_1, title: 'Lesson Preparation', text: 'Teachers prepare for the exam every week to ensure that all the teachers’ class progress is uniform, the class content is consistent, and the quality of the class is guaranteed.'
            },
            {
                circle: de_circle_2, icon: de_icon_2, title: 'Question Discussion', text: 'The group leader leads everyone to solve problems, unify the lecture ideas, and ensure that each student can receive the same knowledge points and solution ideas.'
            },
            {
                circle: de_circle_3, icon: de_icon_3, title: 'Lesson Competition', text: 'We hold teaching competition irregularly, even if the teacher has taught many years, we must always test the level of the teacher and supervise the growth of the teacher.'
            },
            {
                circle: de_circle_4, icon: de_icon_4, title: 'Lesson Examination', text: 'Before the start of the class, the unified assessment will test the teacher’s class skills and preparation level, and only really excellent people can take on platform.'
            }
        ]

        beforeMount() {
            if(document.body.clientWidth <= 1280) {
                this.decContWidth = 1060
            } else {
                this.decContWidth = document.body.clientWidth * 1212 / 1920
            }
        }
    }

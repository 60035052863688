
    import { Component, Vue, Watch } from 'vue-property-decorator'
    let dj_brief = require('./../../../assets/imgs/dj_brief.png'),
        dj_first_try = require('./../../../assets/imgs/dj_first_try.png'),
        dj_second_try = require('./../../../assets/imgs/dj_second_try.png'),
        dj_train = require('./../../../assets/imgs/dj_train.png'),
        dj_internship = require('./../../../assets/imgs/dj_internship.png'),
        dj_assessment = require('./../../../assets/imgs/dj_assessment.png'),
        dj_platform = require('./../../../assets/imgs/dj_platform.png');
    @Component({
        name: 'DashJoingenius'
    })
    export default class DashJoingenius extends Vue {
        private setepLists: Array<any> = [
            { icon: dj_brief, title: 'Resume Delivery', rate: '80% Screening Rate', others: 'Top students are sele- cted from key univer- sities' },
            { icon: dj_first_try, title: 'First Round Test', rate: '60%Passing Rate', others: 'Choose the suitable p- eople from the top st- udents' },
            { icon: dj_second_try, title: 'Reexamine', rate: '30%Passing Rate', others: 'Ability and quality are paid great importance o at t- he same time' },
            { icon: dj_train, title: 'Training (7 days)', rate: '15%Passing Rate', others: 'Love and responsibili- ty are Fangtian’s unc- hanging original inte- ntions' },
            { icon: dj_internship, title: 'Internship (3 months)', rate: '5%Passing Rate', others: 'Make sure that each teacher has no shor- tcomings' },
            { icon: dj_assessment, title: 'Final Test', rate: '2.47%Passing Rate', others: 'Truly the best of the best' },
            { icon: dj_platform, title: 'Before Teaching', rate: '', others: 'Every teacher is trust- worthy' }
        ]
        private djcContWidth: number = 0
        beforeMount() {
            // this.djcContWidth = document.body.clientWidth * 1212 / 1920
            if(document.body.clientWidth <= 1280) {
                this.djcContWidth = 1060
            } else {
                this.djcContWidth = document.body.clientWidth * 1212 / 1920
            }
        }
    }
